import {
    USER_ADD_SUCESS,
    USER_ADD_FAIL

} from "../Types/userType.js";
import axios from "axios";




export const AddUser = (data) => {
    return async (dispatch) => {
        let url = "https://api.bengalsafari.com/api-v1"
        // let url = 'http://localhost:8080/api-v1'
        // console.log(data)
        try {

            const response =  await axios.post(`${url}/admin/auth/register`, data);

            dispatch({
                type: USER_ADD_SUCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message;
            // console.log(data)
            dispatch({
                type: USER_ADD_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    };
};