import { configureStore } from "@reduxjs/toolkit";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { userReducer } from "./reducers/userReducer.js"


const rootReducer = combineReducers({
    users: userReducer
});

const store = configureStore({ reducer: rootReducer });


export default store;
