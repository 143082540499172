import {
    USER_ADD_SUCESS,
    USER_ADD_FAIL,
    SUCCESS_MESSAGE_CLEAR,
    ERROR_CLEAR

} from "../Types/userType.js";
  
  const initialState = {
    loading: true,
    error: "",
    successMessage: "",
  };
  
  
  
  
  export const userReducer = (state = initialState, action) => {
    const { payload, type } = action;
    // console.log(payload);
  
    if (type === USER_ADD_SUCESS) {
      return {
        ...state,
        successMessage: payload.successMessage,
      };
    }
    if (type === USER_ADD_FAIL) {
      return {
        ...state,
        error: payload.error,
      };
    }
    if (type === SUCCESS_MESSAGE_CLEAR) {  
      return {
        ...state,
        successMessage: ""
      }
    }
    if (type === ERROR_CLEAR) {
      return {
        ...state,
        error: ""
      }
    }
    return state;
  }