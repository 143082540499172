import './App.css';
import React, {useState, useEffect} from 'react';

import { useDispatch, useSelector } from "react-redux";
import { AddUser } from "./store/Actions/userAction.js"
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from "./store/Types/userType.js"



function App() {

    const dispatch = useDispatch();
  
    const { error, successMessage} = useSelector((state) => state.users);
  
    // State variables for modal
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', workRole: '', password: "" });
  
    // Function to handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      if(formData && Object.keys(formData).length > 0){
        dispatch(AddUser(formData))
      }
      // Close the modal

    };


    useEffect(()=>{
        if(successMessage){
            setTimeout(()=>{
                dispatch({ type: SUCCESS_MESSAGE_CLEAR})
                setShowModal(false);
            },2000)
        }
        if(error){
            setTimeout(()=>{
                dispatch({ type: ERROR_CLEAR})
            },2000)
        }
    },[error, successMessage])

  return (
    <>
        <header>
            <div id="menu-jk" className="nav-part bg-white shadow">
                <div className="container-xl p-0">
                    <div className="row  bg-white navcol p-3">
                        <div className="col-lg-4">
                            <img className="max-230" src="assets/images/logo.png" alt=""/>
                             <a  data-bs-toggle="collapse" data-bs-target="#menu" className="float-end d-lg-none ps-3"><i className="bi pt-1 fs-1 cp bi-list"></i></a>
                        </div>
                        <div id="menu" className="col-lg-8 text pe-0 d-none d-lg-block">
                            <ul className="fw-bold float-end nacul fs-8 text-uppercase">
                                <li className="float-start act p-3 px-4"><a href="#home">Home</a></li>
                                <li className="float-start p-3 px-4"><a href="#about">About Us</a></li>
                                <li className="float-start p-3 px-4"><a href="#services">Services</a></li>
                                {/* <li className="float-start p-3 px-4"><a href="#blog">Blog</a></li> */}
                                {/* <li className="float-start p-3 px-4"><a href="#testimonial">Testimonial</a></li> */}
                                <li className="float-start p-3 px-4"><a href="#contact">Contact Us</a></li>
                                <li className="float-start p-3 px-4" style={{backgroundColor: "#FF2C9C", borderRadius: "7px"}}><a href="https://app.bengalsafari.com/">Login</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        {/* <!--  ************************* Banner Start Here ************************** -->  */}

        <div id="home" className="banner big-padding bg-white container-fluid">
            <div className="container-xl p-0 max-1200">
                <div className="row">
                    <div className="col-md-6 mb-4 align-self-center">
                        <h6 className="text-primary fw-bolder">It services Provider</h6>
                        <h1 className="fs-11 fw-bolder text-dark">We Help You Take Your Business to The Next Level</h1>
                        <p className="fs-6 text-dark">
                          
"Bengal Safari: Your gateway to exceptional travel experiences. Explore our multi-vendor tourism services seamlessly with our responsive web app, featuring 13 role-based admin panels, and stay connected on the go with our mobile and desktop apps."
                        </p>
      <button className="btn fw-bolder py-3 px-5 mt-4 btn-primary" onClick={() => setShowModal(true)}>
        Start your journey with us <i className="bi bi-chevron-right"></i>
      </button>
                    </div>
                    <div className="col-md-6">
                        <img src="assets/images/banner-img.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>

        {/* <!--  ************************* About US Start Here ************************** -->      */}
           
        <section id="about" className="about-us bg-white border-top big-padding container-fluid">
            <div className="container-xl p-0">
                <div className="section-title row">
                    <h2>About US</h2>
                    <p>"Bengal Safari is a leading provider of multi-vendor tourism services, redefining travel with innovative solutions. Explore, experience, and embark on unforgettable journeys with us."</p>
                </div>
                <div className="row">
                    <div className="col-md-6 p-4">
                        <img src="assets/images/about.png" alt=""/>
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="fs-1 fw-bold">We try to achieve New Benchmark for Multi Tenancy It service.</h2>
                        <h4 className="text-primary my-4 fw-bold">Revolutionizing Multitenancy: Setting Unprecedented IT Service Benchmarks with Innovation, Excellence, and Client-Centric Solutions.</h4>

                        <p className="fs-6 mb-4">Pioneering the future of IT services, we relentlessly pursue new benchmarks in multitenancy. Our innovative approach and client-centric solutions redefine excellence, setting a transformative standard in the industry.</p>

                        <p>
                        Striving for excellence in Multitenancy IT services, we're pioneering innovation to establish unprecedented benchmarks. Our commitment lies in delivering client-centric solutions that redefine industry standards and elevate user experiences.
                        </p>
                    </div>
                </div>
            </div>
        </section>  

        {/* <!--  ************************* Our Services Start Here ************************** -->  */}

        <div id="services" className="our-services bg-white border-top big-padding container-fluid">
            <div className="container-xl p-0 max-1200">
                <div className="section-title row">
                    <h2>Our Services</h2>
                    <p>Elevate your business with our cutting-edge Multitenancy IT services, setting new industry benchmarks through innovation, reliability, and client-focused solutions</p>
                </div>
                <div className="container-xl max-1200">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="border rounded text-center p-4">
                                <img src="assets/images/services/process1.png" alt=""/>
                                <h4 className="fw-bolder my-3"> Providing full IT services</h4>
                                <p className="fs-6">
                                Elevate your digital presence with our comprehensive IT services. We specialize in crafting unique, responsive web applications, along with 13 role-based admin panels, mobile apps, and desktop applications. Seamlessly connect with your audience across platforms for a truly immersive experience
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="border rounded text-center p-4">
                                <img src="assets/images/services/process2.png" alt=""/>
                                <h4 className="fw-bolder my-3">Business Consultant</h4>
                                <p className="fs-6">
                                As dedicated business consultants, we strategize for success. Specializing in IT solutions, we guide you to optimal digital frameworks, ensuring seamless integration of responsive web applications and versatile multi-platform applications.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="border rounded text-center p-4">
                                <img src="assets/images/services/process3.png" alt=""/>
                                <h4 className="fw-bolder my-3">Marketing Automation</h4>
                                <p className="fs-6">
                                Transform your marketing strategy with our AI-based tools. From responsive web applications to dynamic statistics graphs, our marketing automation solutions bring innovation and efficiency to every aspect of your campaign.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!--  ************************* Products Start Here ************************** -->  */}

        {/* <div id="testimonial" className="service big-padding bg-white border-top container-fluid px-4 py-5">
            <div className="container-xl p-0">
                <div className="section-title row mb-3">
                    <h2 className="fw-bolder">Case Study</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ipsum sit nibh amet egestas tellus.</p>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="bg-white shadow-md">
                            <img src="assets/images/case/01.jpg" alt=""/>
                            <div className="p-3 text-center">
                                <h4 className="fs-6 fw-bold">Web Trafic Management</h4>
                                <p>SEO, Marketing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="bg-white shadow-md">
                            <img src="assets/images/case/02.jpg" alt=""/>
                            <div className="p-3 text-center">
                                <h4 className="fs-6 fw-bold">Web App Development</h4>
                                <p>SEO, Marketing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="bg-white shadow-md">
                            <img src="assets/images/case/03.jpg" alt=""/>
                            <div className="p-3 text-center">
                                <h4 className="fs-6 fw-bold">Mobile App Development</h4>
                                <p>SEO, Marketing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="bg-white shadow-md">
                            <img src="assets/images/case/04.jpg" alt=""/>
                            <div className="p-3 text-center">
                                <h4 className="fs-6 fw-bold">SEO Analysis</h4>
                                <p>SEO, Marketing</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div> */}

        {/* <!-- ################## Our Blog #################### --> */}
           
        {/* <div id="blog" className="service border-top sdiv container-fluid px-4 bg-white py-5">
            <div className="container p-0">
               <div className="section-title row mb-3">
                    <h2 className="fw-bolder">Our Blog</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ipsum sit nibh amet egestas tellus.</p>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 mb-4">
                       <div className="serv-cove rounded bg-white p-2">
                            <img src="assets/images/blog/b1.jpg" alt=""/>
                            <div className="p-2">
                                <h5 className="mt-3 fs-7 fw-bold">Top 10 Trends in  Webdesign sit nibh amet  Mauris ipsum sit nibh</h5>
                                <span className="float-start fw-bold fs-8"> Learn More <i className="bi bi-arrow-right"></i></span>
                            </div>
                       </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                       <div className="serv-cove rounded bg-white p-2">
                            <img src="assets/images/blog/b2.jpg" alt=""/>
                            <div className="p-2">
                                <h5 className="mt-3 fs-7 fw-bold">Top 10 Trends in  Webdesign sit nibh amet  Mauris ipsum sit nibh</h5>
                                 <span className="float-start fw-bold fs-8"> Learn More <i className="bi bi-arrow-right"></i></span>
                            </div>
                       </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                       <div className="serv-cove rounded bg-white p-2">
                            <img src="assets/images/blog/b3.jpg" alt=""/>
                            <div className="p-2">
                                <h5 className="mt-3 fs-7 fw-bold">Top 10 Trends in  Webdesign sit nibh amet  Mauris ipsum sit nibh</h5>
                                 <span className="float-start fw-bold fs-8"> Learn More <i className="bi bi-arrow-right"></i></span>
                            </div>
                       </div>
                    </div>
                    
                </div>
            </div>
        </div> */}

        {/* <!--  ************************* Our Services Start Here ************************** -->  */}

        {/* <div id="team" className="our-services bg-white sdiv border-top big-padding container-fluid">
            <div className="container-xl p-0 max-1200">
                <div className="row">
                    <div className="section-title">
                        <h2>Our Team</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris consequat.</p>
                    </div>
                </div>
                <div className="container-xl max-1200">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="rounded text-center">
                                <img src="assets/images/team/team-img1.jpg" alt=""/>
                                <div className="shadow-md mx-4 bg-white p-3 mtm-4">
                                     <h4 className="fw-bolder mb-0 my-3">Mark Antony</h4>
                                     <strong className="text-primary">CEO / Devin Art</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="rounded text-center">
                                <img src="assets/images/team/team-img2.jpg" alt=""/>
                                <div className="shadow-md mx-4 bg-white p-3 mtm-4">
                                     <h4 className="fw-bolder mb-0 my-3">Ange Montery J</h4>
                                     <strong className="text-primary">CFO / Devin Art</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="rounded text-center">
                                <img src="assets/images/team/team-img3.jpg" alt=""/>
                                <div className="shadow-md mx-4 bg-white p-3 mtm-4">
                                     <h4 className="fw-bolder mb-0 my-3">James Anderson</h4>
                                     <strong className="text-primary">CTO / Devin Art</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        {/* <!--  ************************* Testimonial Start Here ************************** --> */}
       
        {/* <section id="testimonial" className="testimonial-container sdiv border-top pb-5 big-padding">
            <div className="container p-0">
             <div className="section-title row">
                <h2>Testimonial</h2>
                <p>Take a look at what people say about us</p>
            </div>
                <div className="row mt-5">
                    <div className="col-md-offset-2 float-auto col-md-12">
                        <div id="testimonial-slider" className="owl-carousel">
                            <div className="testimonial">
                                <div className="pic">
                                    <img src="assets/images/testimonial/member-01.jpg" alt=""/>
                                </div>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda deleniti dolor ipsum molestias mollitia ut. Aliquam aperiam corporis cumque debitis delectus dignissimos. Lorem ipsum dolor sit amet, consectetur.
                                </p>
                                <h3 className="title">williamson
                                    <span className="post"> -  Developer</span>
                                </h3>
                            </div>

                            <div className="testimonial">
                                <div className="pic">
                                    <img src="assets/images/testimonial/member-02.jpg" alt=""/>
                                </div>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda deleniti dolor ipsum molestias mollitia ut. Aliquam aperiam corporis cumque debitis delectus dignissimos. Lorem ipsum dolor sit amet, consectetur.
                                </p>
                                <h3 className="title">Kristina
                                    <span className="post"> - Teacher</span>
                                </h3>
                            </div>


                            <div className="testimonial">
                                <div className="pic">
                                    <img src="assets/images/testimonial/member-03.jpg" alt=""/>
                                </div>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda deleniti dolor ipsum molestias mollitia ut. Aliquam aperiam corporis cumque debitis delectus dignissimos. Lorem ipsum dolor sit amet, consectetur.
                                </p>
                                <h3 className="title">Kristina
                                    <span className="post"> - Teacher</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <footer id="contact" className="bg-dark text-white big-padding pb-0">
            <div className="container-xl ">
                <div className="row">
                    <div className="col-md-4">
                        <div className="aboutcol bg-gray fs-7 bg-dark shadow-lg p-5">
                            <p className="mb-2">
"Bengal Safari, your gateway to immersive travel experiences! 
At Bengalsafari.com, our dedicated team specializes in delivering 
top-notch multi-vendor tourism services. Explore the beauty of 
Bengal and beyond with our curated selection of tours, accommodations, 
and activities. Whether you seek cultural enrichment, wildlife adventures,
 or serene getaways, Bengal Safari is your trusted companion in crafting 
 unforgettable journeys. Discover, book, and embark on a seamless travel 
 experience with us!"
                            </p>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-2 text-center text-primary">
                                        <i className="bi fs-1  bi-geo-alt"></i>
                                    </div>
                                    <div className="col-md-10">
                                        <h5 className="text-light fw-bolder fs-3">Gosaba, west bengal, South 24 pgs</h5>
                                        <p className="fs-6">743370 India</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-2 text-center text-primary">
                                        <i className="bi fs-1 bi-telephone-inbound"></i>
                                    </div>
                                    <div className="col-md-10">
                                        <h5 className="text-light fw-bolder fs-3">+91 6296783642</h5>
                                        <p className="fs-6">Give us Call</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 pt-5">
                                <h4 className="fs-5 fw-bolder text-white">Useful Links</h4>
                                <ul className="row">
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Home</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> About Us</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Services</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Blog</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Contact Us</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Features</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Privacy Policy</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Refund Policy</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Gallery</a></li>
                                    <li className="float-start col-md-6 p-2"><a className="text-white fw-bolder" href=""> Albums</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6  pt-5">
                                <h4 className="fs-5 fw-bolder text-white">Useful Links</h4>

                                <p className="mb-5">Explore responsive web apps, AI marketing tools, and business consulting. Elevate your strategy with insightful data analytics.</p>
    
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control mb-0" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text bg-primary" id="basic-addon2"><i className="bi text-white bi-send"></i></span>
                                    </div>
                                </div>

                            
                                <ul className="text-white pt-1 float-end">
                                    <li className="float-start fs-6 p-3 py-2"><i className="bi bi-facebook"></i></li>
                                    <li className="float-start fs-6 p-3 py-2"><i className="bi bi-twitter"></i></li>
                                    <li className="float-start fs-6 p-3 py-2"><i className="bi bi-instagram"></i></li>
                                    <li className="float-start fs-6 p-3 py-2"><i className="bi bi-linkedin"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div className="copy">
            <div className="container">
                <a href="http://bengalsafari.com/">2023 &copy; All Rights Reserved | Designed and Developed by bengalsafari.com</a>

                <span>
                    <a href=""><i className="fab fa-github"></i></a>
                    <a href=""><i className="fab fa-google-plus-g"></i></a>
                    <a href="https://in.pinterest.com/prabnr/pins/"><i className="fab fa-pinterest-p"></i></a>
                    <a href="https://twitter.com/prabinraja89"><i className="fab fa-twitter"></i></a>
                    <a href="https://www.facebook.com/freewebtemplatesbysmarteye"><i className="fab fa-facebook-f"></i></a>
                </span>
            </div>
        </div>

              {/* Modal */}
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Start Your Journey</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                {/* Form inside the modal */}
                {successMessage ? 
                    <div style={{width: "100%", height: "50px", padding: "12px", backgroundColor: "#53D815", borderRadius: "7px"}}>
                    <p style={{color: "#ffffff"}}>{successMessage}</p>
                    </div>:null}
                    {error ? <div style={{width: "100%", height: "50px", padding: "12px", backgroundColor: "#ED1C1C", borderRadius: "7px"}}>
                    <p style={{color: "#ffffff"}}>{error}</p>
                    </div>:null}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="name" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input type="tel" className="form-control" id="phone" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="workRole" className="form-label">Work Role</label>
                    <select className="form-select" id="workRole" onChange={(e) => setFormData({ ...formData, workRole: e.target.value })}>
                      <option value="">Select Work Role</option>
                      <option value="HAgent">Agent</option>
                      <option value="HOwner">Hotel</option>
                      <option value="Cars">Cars</option>
                      <option value="LOwner">Launch (boat)</option>
                      <option value="FAgent">Food Agent</option>
                      <option value="TGuide">Tourist Guide</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control" id="password" onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                  </div>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
